<template>
  <div>
    <div class="flex flex-col font-Inter px-10">
      <Loading v-if="loading" />
      <div class="lg:flex lg:items-center lg:justify-between mb-3">
        <div class="flex-1 min-w-0 flex justify-between py-4">
          <div
            class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
          >
            <h2
              class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
            >
              Bon de Commande N° {{ document.document_no }}
            </h2>
          </div>
        </div>
      </div>
      <div class="mb-20">
        <div class="flex flex-col mx-auto bg-white p-5 shadow">
          <el-form
            :model="document"
            :rules="rules"
            ref="documentForm"
            :label-position="labelPosition"
          >
            <div class="flex justify-between items-start">
              <div class="flex flex-col w-1/2">
                <search-customer
                  @customer="handleSelectedCustomer"
                  @search-customer="handleSearchCustomer"
                  :customerInfo="customerInfo"
                  :customers="customers"
                />
              </div>
              <div class="flex flex-col justify-end items-end w-1/2">
                <div
                  class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
                >
                  Montant: {{ totalTTC | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="flex">
                  <el-form-item class="mr-2" prop="date" label="Date">
                    <el-date-picker
                      v-model="document.date"
                      type="date"
                      placeholder="jj-mm-aaaa"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="Date de livraison" class="mr-2">
                    <el-date-picker
                      v-model="document.shipping_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
                <div class="flex">
                  <el-form-item label="Condifition de paiement">
                    <el-select
                      v-model="document.terms"
                      @change="handleDueDay"
                      class="mr-3"
                    >
                      <el-option
                        v-for="item in duedays"
                        :key="item.id"
                        :label="item.terms"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Date d'échéance">
                    <el-date-picker
                      v-model="document.due_date"
                      type="date"
                      format="dd MMM.yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
                <el-form-item label="Agent commercial">
                  <el-select
                    v-model="document.sale_agent_number"
                    filterable
                    placeholder="Selectionner"
                    clearable
                  >
                    <el-option
                      v-for="item in employees"
                      :key="item.number"
                      :label="item.full_name"
                      :value="item.number"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Note">
                  <el-input
                    style="width: 100%"
                    type="textarea"
                    maxlength="10"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    show-word-limit
                    placeholder="Entrez quelque chose"
                    v-model="document.note"
                  />
                </el-form-item>
              </div>
            </div>
          </el-form>

          <add-article
            :articles="articles"
            :items="items"
            :subTotal="totalHT"
            :totalTTC="totalTTC"
            :sale="true"
            :check="false"
            @delete="handleDeleteItem"
            @search-article="handleSearchArticle"
            @add-item-article="handeAddArticle"
            @discount="updateDiscount"
            @percentDiscount="updatePercentDiscount"
            @taxe="updateTaxe"
            @shipping="updateShipping"
          />
        </div>
      </div>
    </div>
    <page-footer>
      <div class="flex justify-between px-10 py-2">
        <div>
          <div
            class="text-2xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            TOTAL: {{ totalTTC | moneyFilter }} {{user.currency_code}}
          </div>
        </div>

        <div>
          <el-button
            type="infos"
            icon="el-icon-save"
            plain
            @click="handleCreate('DRAFT')"
            :disabled="!itemsNoEmpty || totalHT <= 0"
            >Enregistrer au brouillon</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-check"
            @click="handleCreate('OPEND')"
            :disabled="!itemsNoEmpty || totalHT <= 0"
            >Enregistrer et quitter</el-button
          >
        </div>
      </div>
    </page-footer>
  </div>
</template>

<script>
import { getDocumentSaleNo, newDocument } from "@/api/sales2";
import { getEmployeesList } from "@/api/employee";
import { fetchDueDays } from "@/api/settings";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import AddArticle from "@/components/AddArticle";
import SearchCustomer from "@/components/AddCustomer";
import Loading from "@/components/Loanding/Full";
import PageFooter from "@/components/PageFooter";
import {mapGetters} from "vuex";
export default {
  name: "CreateSaleOrder",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { AddArticle, SearchCustomer, Loading, PageFooter },
  data() {
    return {
      typeDoc: "SALE_ORDER",
      labelPosition: "top",
      loading: false,
      articles: [],
      employees: [],
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        contact_name: "",
        account_credit_balance: 0,
        account_credit_number: "",
        account_credit_title: "",
      },
      customers: [],
      duedays: [],
      articleName: "",
      customerName: "",
      selectedArticle: "",
      dialogVisibleValid: false,

      terms: [],
      loadingArticle: false,
      loadingCustomer: false,
      items: [
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: 0,
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
      ],
      contactId: "",
      document: {
        contact_id: "",
        due_date: "",
        shipping_date: "",
        percent_tax: 0,
        percent_discount: true,
        discount: 0,
        date: formatDate(new Date()),
        terms: "",
        document_no: "",
        shipping_cost: 0,
        shipping_to: "",
        billing_to: "",
        status: "OPEND",
        currency: "",
        sale_agent_number: "",
        line_items: [],
      },
      rules: {
        contact_id: [
          {
            required: true,
            message: "Veuillez selectionner un client",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "La date est obligatoire",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user", "preferences"]),
    totalHT: function () {
      var subtotal = 0;

      this.items.forEach((el) => {
        let totalHT = el.quantity * el.price;
        let discount = 0;
        let tax;
        if (el.percent_discount) {
          discount = (totalHT * el.discount) / 100;
        } else {
          discount = el.discount;
        }
        tax = ((totalHT - discount) * el.percent_tax) / 100;
        subtotal += totalHT - discount + tax;
      });

      return Math.floor(subtotal);
    },
    totalTTC: function () {
      var total = 0;
      if (this.document.percent_discount) {
        total =
          this.totalHT +
          this.document.shipping_cost -
          (this.totalHT * this.document.discount) / 100 +
          (this.totalHT * this.document.percent_tax) / 100;
      } else {
        total =
          this.totalHT +
          this.document.shipping_cost -
          this.document.discount +
          (this.totalHT * this.document.percent_tax) / 100;
      }
      return Math.floor(total);
    },
    itemsNoEmpty: function () {
      return this.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {
      const customer = this.$route.params.customer;
      if (customer) {
          this.customerInfo = {...customer};
          this.customers.push( {
              ...customer
          })
      }
    this.fetchDueDays();
    this.initData();
    this.fetchEmployee();
  },
  methods: {
    handleEdit(row) {
      row.edit = !row.edit;
    },

    async fetchEmployee() {
      await getEmployeesList("SALES_AGENT")
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "danger",
          });
        });
    },

    async fetchDueDays() {
      await fetchDueDays().then((res) => {
        this.duedays = res.data;
      });
    },

    handleDueDay() {
      const date = new Date();
      const terms = this.duedays.find((el) => el.id === this.document.terms);
      date.setTime(date.getTime() + 3600 * 1000 * 24 * terms.days);

      this.document.due_date = formatDate(date);
    },
    async initData() {
      await getDocumentSaleNo(this.typeDoc).then((res) => {
        this.document.document_no = res.data.number;
      });
      this.processingData();
    },
    handleDelete(index) {
      this.document.line_items.splice(index, 1);
    },

    createData() {
      this.$refs["documentForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          const terms = this.duedays.find(
            (el) => el.id === this.document.terms
          );

          this.document.line_items = this.items.filter(
            (el) => el.article_id !== "" && el.article_id !== undefined
          );
          this.document.terms ? terms.terms : undefined;
            this.document.currency = this.user.currency_code;
          newDocument(this.document, this.typeDoc)
            .then((res) => {
              setTimeout(() => {
                this.loading = false;
                this.$router.push(`/sales/orders/${res.data._id}`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              }

              if (err.data.status === 409) {
                this.$message({
                  message: err.data.message,
                  type: "danger",
                });
              }
            });
        }
      });
    },
    handleCleanItem() {
      this.document.line_items = [];
    },

    handleSearchArticle(data) {
      this.articles = data;
    },
    handeAddArticle() {
      this.items.push({
        article_id: "",
        article_no: "",
        article_name: "",
        price_purchase: 0,
        price_sale: 0,
        description: "",
        price: "",
        percent_tax: 0,
        percent_discount: this.preferences.currentVal === 'true',
        discount: 0,
        quantity: 0,
        warehouse_code: "",
        warehouse_name: "",
        stock_onhand: "",
        stocks: [],
      });
    },
    handleSelectedCustomer(id) {
      // selectionner le contact
      const customer = this.customers.find((c) => c.contact_id === id);
      this.customerInfo = Object.assign({}, customer);
      this.document.contact_id = customer.contact_id;
    },
    handleDeleteItem(id) {
      const index = this.items.findIndex((el) => el.article_id === id);
      this.items.splice(index, 1);
    },
    handleSearchCustomer(data) {
      this.customers = data;
    },
    handleCreate(e) {
      this.document.status = e;
      this.createData();
    },

    updateDiscount(e) {
      if (this.document.percent_discount) {
        if (e > 100) {
          this.document.discount = 0;
        } else {
          this.document.discount = e;
        }
      } else {
        this.document.discount = e;
      }
    },
    updatePercentDiscount(e) {
      this.document.percent_discount = e;
      this.document.discount = 0;
    },
    updateTaxe(e) {
      this.document.percent_tax = e;
    },
    updateShipping(e) {
      this.document.shipping_cost = e;
    },
    processingData(){
      this.document.percent_discount = this.preferences.currentVal === 'true';
      this.items.map(el => {
        el.percent_discount = this.preferences.currentVal === 'true';
        return el;
      })
    }
  },
};
</script>

<style></style>
